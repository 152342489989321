/* Quote section */
.notes .quote-section {
	background-color: $black;
	blockquote {
		font-family: Advent Pro, helvetica, sans-serif;
		font-size: 2.5em;
		color: $fire-bush;
		padding: 0;
		margin: 150px 0 0 0;
	}
	p {
		font-family: Advent Pro, helvetica, sans-serif;
		font-size: 2em;
		color: $fire-bush;
		padding: 50px 0;
	}
}
/* End quote section */

/* Articles section */
.articles-section {
	background-color: $white;
	padding: 50px 0;

	h1 {
		font-family: Advent Pro, helvetica, sans-serif;
		font-size: 2em;
		padding: 0;
		margin: 1rem 0 1rem 0;
		color: $coral-tree;
	}
	.article {
		display: block;
		padding: 250px 0px 0px 0px;
		margin-bottom: 50px;
		background-repeat: no-repeat;
		background-size: cover;
		border-top-left-radius: 20px;
		-webkit-border-top-left-radius: 20px;
		-moz-border-top-left-radius: 20px;
		border-top-right-radius: 20px;
		-webkit-border-top-right-radius: 20px;
		-moz-border-top-right-radius: 20px;
	}
	.article-details {
		display: block;
		padding: 20px 40px;
		background-color: $charcoal;
		.fa-calendar-day,
		.fa-clock {
			color: $white;
		}
	}
	.article h2 {
		font-family: Advent Pro, helvetica, sans-serif;
		font-size: 2em;
		padding: 0;
		margin: 1rem 0 1rem 0;
		color: $fire-bush;
	}
	.article-link,
	.article-link:focus,
	.article-link:hover,
	.article-link:visited,
	.article-link:active {
		color: $fire-bush;
		text-decoration: none;
	}
	p {
		font-family: Advent Pro, helvetica, sans-serif;
		font-size: 1.5em;
		font-style: italic;
		margin: 1rem 0 1rem 0;
		padding: 0;
		color: $fire-bush;
	}
}
/* End articles section */