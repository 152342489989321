// Grid settings
$enable-flex:           true;
$main-sm-columns:       12;
$sidebar-sm-columns:    4;

// Colors
$brand-primary:         #27ae60;
$white:					#ffffff;
$coral-tree:			#a6615e;
$black:					#000000;
$fire-bush:				#e6a720;
$light-grey:			#c1c1c1;
$alto:					#CECECE;
$charcoal:				#404040;
$red:					#DD0000;
$dark-green:			#05AA36;

// fonts
@font-face {
    font-family: Advent Pro;
    src: url("../../dist/fonts/AdventPro.ttf") format("truetype");
}