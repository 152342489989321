.single {
	article {
		padding-top: 12px;
	}
	.title-row {
		border-top: 1px solid $light-grey;
		border-bottom: 1px solid $light-grey;
	}
	h1 {
		font-family: Advent Pro, helvetica, sans-serif;
		font-size: 2em;
		padding: 0;
		margin: 1rem 0 1rem 0;
		color: $coral-tree;
		text-align: center;
	}
	.post-date,
	.reading-time {
		font-family: Advent Pro, helvetica, sans-serif;
		font-size: 1.5em;
		font-style: italic;
		margin: 1rem 0 1rem 0;
		padding: 0;
		color: $coral-tree;
		.fa-calendar-day,
		.fa-clock {
			color: $charcoal;
		}
	}
	.article-content {
		padding-bottom: 20px;
		h2 {
			margin-bottom: 30px;
		}
		p {
			font-family: Advent Pro, helvetica, sans-serif;
			font-size: 1.3em;
			margin: 1rem 0 1rem 0;
			padding: 0;
			color: $coral-tree;	
		}
		blockquote {
			font-family: Calibri,Candara,Segoe,Segoe UI,Optima,Arial,sans-serif;
			font-size: 1.5em;
			font-weight: bold;
			font-style: italic;
			border-top: 1px solid $alto;
			border-bottom: 1px solid $alto;
			margin: 1rem 0;
			padding: 1rem 0;
			text-align: center;
			color: $charcoal;
		}
		ul {
			li {
				font-family: Advent Pro, helvetica, sans-serif;
				font-size: 1.3em;
				margin: 0;
				padding: 0;
				color: $coral-tree;
			}
		}
		.giphy-embed {
			width: 50%; 
			height: 0; 
			margin: auto;
			padding-bottom: 38%; 
			position: relative;
			text-align: center;
		}
	}

	.related-posts {
		padding-bottom: 50px;
		h2 {
			margin-bottom: 30px;
		}
	}
}