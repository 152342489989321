@media screen and (min-width: 421px) and (max-width: 568px) {
	.abilities {
		.fas, .far {
			font-size: 9px;
		}
	}
	footer {
		.social {
			padding: 20px;
			height: 550px;
		}
		.linkedin {
			position: relative;
			top: -50px;
			left: 0px;
		}
		.follow {
			position: relative;
			top: -80px;
			left: 215px;
		}
		.facebook {
			position: relative;
			top: -115px;
			left: 433px;
		}
		.back-to-top {
			bottom: 3px;
		}
		.copyright {
			padding-bottom: 120px;
		}
	}
}

@media screen and (max-width: 568px) {
	.cookie-banner {
		.accept {
			margin: 0 20px 0 0;
		}
	}
}

@media screen and (max-width: 500px) {
	#outdated {
		height: 280px;
		span {
			display: block;
			margin-top: 50px;
			font-size: 1.5em;
			line-height: 1.5em;
		}
		p {
			font-size: 1.2em;
			line-height: 1.2em;
		}
	}
	.single {
		.article-content {
			.giphy-embed {
				width: 100%; 
				height: 0; 
				padding-bottom: 75%; 
				position: relative;
			}
		}
	}
	#projects-carousel-image {
		min-height: 250px;
	}
	.articles-section {
		.article {
			padding: 187px 0px 0px 0px;
		}
	}
}