footer {
	background-color: $black;
	h2 {
		font-family: Advent Pro, helvetica, sans-serif;
		font-size: 2.5em;
		color: $fire-bush;
		padding: 0;
	}
	h3 {
		font-family: Advent Pro, helvetica, sans-serif;
		font-size: 2.3em;
		color: $fire-bush;
		padding: 0;
	}
	p {
		font-family: Advent Pro, helvetica, sans-serif;
		font-size: 1.5em;
		line-height: 1em;
		color: $fire-bush;
		padding: 0;
	}
	.contact {
		padding-top: 50px;
		padding-bottom: 20px;
	}
	.get-in-touch {
		padding-bottom: 20px;
	}
	.links,
	.links:visited,
	.links:active,
	.links:focus,
	.links:hover {
		display: inline-block;
		font-family: Advent Pro, helvetica, sans-serif;
		font-size: 1em;
		line-height: 1em;
		color: $fire-bush;
		padding: 0;
		margin: 0;
		text-align: left;
		vertical-align: middle;
		text-decoration: underline;
	}
	.social {
		height: 450px;
		padding-bottom: 20px;
	}
	.linkedin {
		position: relative;
		top: -41px;
		left: 233px;
	}
	.follow {
		position: relative;
		top: -69px;
		left: 280px;
	}
	.facebook {
		position: relative;
		top: -103px;
		left: 375px;
	}
	.tweet-widget {
		position: relative;
		top: -88px;
	}
	.copyright-container {
		background-color: $charcoal;
		padding: 10px 0;
	}
	.copyright {
		font-family: Advent Pro, helvetica, sans-serif;
		font-size: 1em;
		color: $fire-bush;
		padding: 0 0 80px;
		text-align: center;
		span {
			display: inline-block;
			vertical-align: middle;
			line-height: 1em;
			margin-right: 20px;
		}
	}
	.back-to-top {
		display: none;
		padding: 10px 15px;
		position: fixed;
		right: 0px;
		bottom: 100px;
		z-index: 20;
		.fa-angle-up {
			color: $fire-bush;
			font-size: 32px;
		}
	}
}

.cookie-banner {
	display: none;
	border-top: 1px solid $fire-bush;
	background-color: $black;
	padding: 20px 40px;
	position: fixed;
	z-index: 20;
	bottom: 0;
	div {
		display:inline-block;
	}
	p {
		color: $fire-bush;
		font-family: Advent Pro, helvetica, sans-serif;
		font-size: 1em;
		line-height: 1em;
	}
	.accept {
		display:inline-block;
		font-family: Advent Pro, helvetica, sans-serif;
		font-size: 1em;
		line-height: 1em;
		margin: 0 20px 0 40px;
		background-color: $fire-bush;
		border-radius: 20px;
		-webkit-border-radius: 20px;
		-moz-border-radius: 20px;
		color: $black;
		border:0;
		padding:10px 20px;
		cursor:pointer;
	}
	.accept:focus {
		outline: 0;
	}
	.dismiss,
	.dismiss:active,
	.dismiss:focus,
	.dismiss:visited,
	.dismiss:hover {
		font-family: Advent Pro, helvetica, sans-serif;
		font-size: 1em;
		line-height: 1em;
	}
}