@media screen and (min-width: 361px) and (max-width: 375px) {
	.articles-section {
		.article {
			padding: 161px 0px 0px 0px;
		}
	}

	footer {
		.social {
			padding: 20px;
			height: 450px;
		}
		.linkedin {
			position: relative;
			top: -50px;
			left: 0px;
		}
		.follow {
			position: relative;
			top: -80px;
			left: 130px;
		}
		.facebook {
			position: relative;
			top: -114px;
			left: 268px;
		}
		.back-to-top {
			bottom: 3px;
		}
	}
}