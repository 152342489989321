@media screen and (min-width: 768px) and (max-width: 811px) {
	#menu-header-menu {
		margin-left: 19%;
	}
	.abilities {
		#section-2, #section-3 {
			.ability, .tool {
				font-size: 1.2em;
			}
			.fas, .far {
				font-size: 9px;
			}
		}
	}
	footer {
		.social {
			padding: 20px;
			height: 400px;
		}
		.linkedin {
			position: relative;
			top: -38px;
			left: 149px;
		}
		.follow {
			position: relative;
			top: -66px;
			left: 188px;
		}
		.facebook {
			position: relative;
			top: -100px;
			left: 277px;
		}
		.back-to-top {
			bottom: 3px;
		}
	}
}