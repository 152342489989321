@media screen and (min-width: 376px) and (max-width: 420px) {
	footer {
		.social {
			padding: 20px;
			height: 470px;
		}
		.linkedin {
			position: relative;
			top: -50px;
			left: 0px;
		}
		.follow {
			position: relative;
			top: -80px;
			left: 150px;
		}
		.facebook {
			position: relative;
			top: -114px;
			left: 308px;
		}
		.back-to-top {
			bottom: 3px;
		}
	}
}

@media screen and (max-width: 420px) {
	#section-2 {
		h2 {
			font-size: 2em;
		}
	}
	.quote-section {
		h1 {
			font-size: 2em;
			padding: 20px;
			margin: 0px;
		}
		blockquote {
			font-size: 1.5em;
			padding: 0px 20px 20px 20px;
			margin: 0px;
		}
		p {
			font-size: 1em;
			padding: 0px 20px 20px 20px;
			margin: 0px;
		}
	}
	.project-background {
		.project-title {
			font-size: 2em;
			padding: 20px;
			margin: 0px;
		}
		.project-content {
			font-size: 1em;
			padding: 0px 20px 20px 20px;
			margin: 0px;
		}
	}
	.abilities {
		.fas, .far {
			font-size: 9px;
		}
	}
	footer {
		h2 {
			font-size: 2em;
			padding: 0px 20px;
		}
		h3 {
			font-size: 1.3em;
			padding: 0px;
		}
		p {
			font-size: 1em;
			padding: 20px 0px;
		}
		.contact {
			padding: 20px;
		}
		.get-in-touch {
			padding: 0px 20px;
		}
		.social-title {
			padding: 20px 0px 50px 0px;
		}
		.copyright {
			font-size: 0.9em;
			padding-bottom: 125px;
			text-align: center;
			span {
				display: block;
			}
		}
	}
}