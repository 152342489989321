@media screen and (max-width: 320px) {
	.related-posts {	
		.articles-section {
			.article {
				padding: 48.7% 0px 0px 0px;
			}
		}
	}
	.articles-section {
		.article {
			padding: 141px 0px 0px 0px;
		}
	}
	footer {
		.g-recaptcha {
			div {
				width: 280px !important;
				iframe {
					width: 280px !important;
				}
			}
		}
		.social {
			padding: 20px;
			height: 450px;
		}
		.linkedin {
			position: relative;
			top: -50px;
			left: 0px;
		}
		.follow {
			position: relative;
			top: -80px;
			left: 95px;
		}
		.facebook {
			position: relative;
			top: -114px;
			left: 213px;
		}
		.back-to-top {
			bottom: 25px;
		}
		.copyright {
			padding-bottom: 140px;
		}
	}
}