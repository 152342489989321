@media screen and (min-width: 569px) and (max-width: 667px) {
	.abilities {
		.fas, .far {
			font-size: 9px;
		}
	}
	footer {
		.social {
			padding: 20px;
			height: 550px;
		}
		.linkedin {
			position: relative;
			top: -50px;
			left: 64px;
		}
		.follow {
			position: relative;
			top: -80px;
			left: 270px;
		}
		.facebook {
			position: relative;
			top: -115px;
			left: 497px;
		}
		.tweet-widget {
			text-align: center;
		}
		.back-to-top {
			bottom: 3px;
		}
		.copyright {
			padding-bottom: 120px;
		}
	}
}