@media screen and (min-width: 812px) and (max-width: 823px) {
	#menu-header-menu {
		margin-left: 25%;
	}
	.abilities {
		.fas, .far {
			font-size: 9px;
		}
	}
	footer {
		.social {
			padding: 20px;
			height: 415px;
		}
		.linkedin {
			position: relative;
			top: -39px;
			left: 151px;
		}
		.follow {
			position: relative;
			top: -67px;
			left: 200px;
		}
		.facebook {
			position: relative;
			top: -101px;
			left: 299px;
		}
		.back-to-top {
			bottom: 3px;
		}
	}
}

@media screen and (max-width: 823px) {
	.articles-section {
		padding: 50px 15px;
	}
}