@media screen and (min-width: 321px) and (max-width: 360px) {
	.articles-section {
		.article {
			padding: 161px 0px 0px 0px;
		}
	}

	footer {
		.social {
			padding: 20px;
			height: 465px;
		}
		.linkedin {
			position: relative;
			top: -50px;
			left: 0px;
		}
		.follow {
			position: relative;
			top: -80px;
			left: 117px;
		}
		.facebook {
			position: relative;
			top: -114px;
			left: 253px;
		}
		.back-to-top {
			bottom: 3px;
		}
	}
}