@media screen and (min-width: 1281px) and (max-width: 1366px) {
	#menu-header-menu {
		margin-left: 57%;
	}
	.abilities {
		.fas, .far {
			font-size: 9px;
		}
	}
	.related-posts {
		.articles-section {
			width: 26%;
			max-width: initial;
			padding: 0px;
		}
		.article {
			padding: 83px 0px 0px 0px;
			margin-bottom: 20px;
		}
		.article-details {
			padding: 10px;
		}
		.article h2 {
			font-size: 1em;
		}
	}
	footer {
		h3 {
			font-size: 1.4em;
		}
		p {
			font-size: 1.2em;
		}
		.social {
			height: 390px;
			.linkedin {
				position: relative;
				top: -39px;
				left: 123px;
			}
			.follow {
				position: relative;
				top: -67px;
				left: 159px;
			}
			.facebook {
				position: relative;
				top: -101px;
				left: 246px;
			}
		}
	}
}