@media screen and (min-width: 668px) and (max-width: 767px) {
	.abilities {
		.fas, .far {
			font-size: 9px;
		}
	}
	footer {
		.social {
			padding: 20px;
			height: 550px;
		}
		.linkedin {
			position: relative;
			top: -50px;
			left: -5px;
		}
		.follow {
			position: relative;
			top: -85px;
			left: 150px;
		}
		.facebook {
			position: relative;
			top: -118px;
			left: 310px;
		}
	}
}

@media screen and (max-width: 767px) {
	.section-bar {
		.section-block {
			margin: 10px 0;
			a,
			a:focus,
			a:hover,
			a:visited,
			a:active {
				height: 48px;
				width: 48px;
				line-height: 3em;
    			border-radius: 7.5px;
    			-webkit-border-radius: 7.5px;
    			-moz-border-radius: 7.5px;
				.fa-angle-down {
					display: block;
				}
			}
		} 
	}
	.quote-section {
		h1 {
			font-size: 2em;
			padding: 20px;
			margin: 0px;
		}
		blockquote {
			font-size: 1.5em;
			padding: 0px 20px 20px 20px;
			margin: 0px;
		}
		p {
			font-size: 1em;
			padding: 0px 20px 20px 20px;
			margin: 0px;
		}
	}
	.project-background {
		.project-title {
			font-size: 2em;
			padding: 20px;
			margin: 0px;
		}
		.project-content {
			font-size: 1em;
			padding: 0px 20px 20px 20px;
			margin: 0px;
		}
	}
	.articles-section {
		.article {
			background-size: contain;
		}
	}
	footer {
		h2 {
			font-size: 2em;
			padding: 0px 20px;
		}
		h3 {
			font-size: 1.3em;
			padding: 0px;
		}
		p {
			font-size: 1em;
			padding: 20px 0px;
		}
		.contact {
			padding: 20px;
		}
		.get-in-touch {
			padding: 0px 20px;
		}
		.social-title {
			padding: 20px 0px 50px 0px;
		}
		.copyright {
			font-size: 0.9em;
			padding-bottom: 80px;
			text-align: center;
		}
	}
}