@media screen and (min-width: 1367px) and (max-width: 1440px) {
	#menu-header-menu {
		margin-left: 59%;
	}
	.abilities {
		#section-2, #section-3 {
			.ability, .tool {
				font-size: 1.5em;
			}
		}
		.fas, .far {
			font-size: 14px;
		}
	}
	.related-posts {
		.articles-section {
			width: 25%;
			max-width: initial;
			padding: 0px;
		}
		.article {
			padding: 84px 0px 0px 0px;
			margin-bottom: 20px;
		}
		.article-details {
			padding: 10px;
		}
		.article h2 {
			font-size: 1em;
		}
	}
	footer {
		h3 {
			font-size: 1.5em;
		}
		p {
			font-size: 1.3em;
		}
		.social {
			height: 400px;
			.linkedin {
				position: relative;
				top: -39px;
				left: 135px;
			}
			.follow {
				position: relative;
				top: -67px;
				left: 172px;
			}
			.facebook {
				position: relative;
				top: -101px;
				left: 259px;
			}
		}
	}
}

@media screen and (min-width: 1441px) {
	#section-2,
	#section-3 {
		h2 {
			font-size: 3em;
			padding: 50px 0;
		}
	}
	.abilities {
		#section-2, #section-3 {
			.ability, .tool {
				font-size: 1.5em;
			}
		}
		.fas, .far {
			font-size: 14px;
		}
	}
	.related-posts {
		.articles-section {
			width: 28%;
			max-width: initial;
			padding: 0px;
		}
		.article {
			padding: 118px 0px 0px 0px;
			margin-bottom: 20px;
		}
		.article-details {
			padding: 10px;
		}
		.article h2 {
			font-size: 1em;
		}
	}
}