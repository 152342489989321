@media screen and (min-width: 1025px) and (max-width: 1280px) {
	#menu-header-menu {
		margin-left: 54%;
	}
	.abilities {
		.fas, .far {
			font-size: 9px;
		}
	}
	.related-posts {
		.articles-section {
			width: 26%;
			max-width: initial;
			padding: 0px;
		}
		.article {
			padding: 77px 0px 0px 0px;
			margin-bottom: 20px;
		}
		.article-details {
			padding: 10px;
		}
		.article h2 {
			font-size: 1em;
		}
	}
	footer {
		h3 {
			font-size: 1.2em;
		}
		p {
			font-size: 1em;
		}
		.social {
			height: 375px;
			.linkedin {
				position: relative;
				top: -37px;
				left: 104px;
			}
			.follow {
				position: relative;
				top: -65px;
				left: 136px;
			}
			.facebook {
				position: relative;
				top: -99px;
				left: 219px;
			}
		}
	}
}