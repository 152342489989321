#projects-carousel-image {
	min-height: 500px;
}

/* Image section */
.image-container {
	padding-top: 12px;
}
/* End Image section */

/* Quote section */
.quote-section {
	background-color: $black;
	h1 {
		font-family: Advent Pro, helvetica, sans-serif;
		font-size: 3em;
		color: $fire-bush;
		padding: 50px 0;
	}
	blockquote {
		font-family: Advent Pro, helvetica, sans-serif;
		font-size: 2.5em;
		color: $fire-bush;
		padding: 50px 0;
	}
	p {
		font-family: Advent Pro, helvetica, sans-serif;
		font-size: 2em;
		color: $fire-bush;
		padding: 50px 0;
	}
}
/* End quote section */

/* Projects section */
.carousel-inner {
	background-color: $black;
}
.project-background {
	background-color: $black;
	.project-title {
		font-family: Advent Pro, helvetica, sans-serif;
		font-size: 2.5em;
		color: $fire-bush;
		padding: 50px 0;
	}
	.project-content {
		font-family: Advent Pro, helvetica, sans-serif;
		font-size: 1.5em;
		color: $fire-bush;
		padding-bottom: 20px 120px;
	}
}
/* End Projects section */