.bg-image {
	background: 50% 50%/cover no-repeat;
    overflow: hidden;
    background-origin: content-box;
}
.no-padding {
	padding: 0;
}
.minimal-link {
	border: 0;
	text-decoration: none;
}
.hidden {
	display: none;
}
.text-left {
	text-align: left;
}