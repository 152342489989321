.abilities {
	.header-background {
		min-height: 500px;
		height: 500px;
		.header-quote {
			font-family: Advent Pro, helvetica, sans-serif;
			color: $fire-bush;
			padding-top: 200px;
			font-size: 3em;
		}
		.header-author {
			font-family: Advent Pro, helvetica, sans-serif;
			color: $fire-bush;
			padding-top: 25px;
			font-size: 2em;
		}
	}
	#section-2, #section-3 {
		background-color: $black;
		h2 {
			font-family: Advent Pro, helvetica, sans-serif;
			font-size: 3em;
			color: $fire-bush;
			padding: 50px 0;
		}
		.ability, .tool {
			font-family: Advent Pro, helvetica, sans-serif;
			margin-bottom: 0px;
			color: $fire-bush;
		}
		.fas, .far {
			color: $fire-bush;
			vertical-align: bottom;
		}
	}
}