@media screen and (min-width: 569px) and (max-width: 640px) {
	footer {
		.social {
			padding: 20px;
			height: 550px;
		}
		.linkedin {
			position: relative;
			top: -50px;
			left: 50px;
		}
		.follow {
			position: relative;
			top: -80px;
			left: 215px;
		}
		.facebook {
			position: relative;
			top: -115px;
			left: 483px;
		}
		.back-to-top {
			bottom: 3px;
		}
	}
}