// Search form
.search-form {
  @extend .form-inline;
}
.search-form label {
  font-weight: normal;
  @extend .form-group;
}
.search-form .search-field {
  @extend .form-control;
}
.search-form .search-submit {
  @extend .btn;
  @extend .btn-secondary;
}

label {
	color: $fire-bush;
	font-family: Advent Pro, helvetica, sans-serif;
}

.form-control:focus {
	border-color: $fire-bush;
}

.first-name-error,
.email-error {
	display:none;
	color: $red;
	font-family: Advent Pro, helvetica, sans-serif;
}

.btn-primary,
.btn-primary:active,
.btn-primary:hover,
.btn-primary:focus,
.btn-primary:visited {
	background-color: $fire-bush;
	border-color: $fire-bush;
	color: $black;
	font-family: Advent Pro, helvetica, sans-serif;
	cursor:pointer;
}

.btn-primary:disabled {
	background-color: $charcoal;
	border-color: $charcoal;
	cursor: auto;
}

.btn-primary:focus {
	box-shadow: 0 0 0 3px $fire-bush;
}

.email-send-error {
	display: none;
	padding: 20px;
	background-color: $red;
	color: $black;
	font-family: Advent Pro, helvetica, sans-serif;
	text-align: center;
}

.email-send-error.fixed-top {
	top: 80px;
}

.confirmation {
	display: none;
	padding: 20px;
	background-color: $dark-green;
	color: $white;
	font-family: Advent Pro, helvetica, sans-serif;
	text-align: center;
}

.confirmation.fixed-top {
	top: 80px;
}