.about {
	.header-background {
		min-height: 500px;
		height: 500px;
		.header-content {
			padding-top: 120px;
			font-size: 2.5em;
		}
		.header-title {
			padding-top: 25px;
			font-size: 4em;
		}
	}
	#section-2,
	#section-3 {
		background-color: $black;
		h2 {
			font-family: Advent Pro, helvetica, sans-serif;
			color: $fire-bush;
			padding: 20px 0;
		}
		h3 {
			font-size: 1.25rem;
		}
		.career-title {
			text-align: left;
		}
		.careers-container,
		.education-container {
			#accordion,
			#accordionTwo {
				.card {
					background-color: $black;
					.card-header {
						border-top: 1px solid $fire-bush;
						border-left: 1px solid $fire-bush;
						border-right: 1px solid $fire-bush;
						border-radius: 5px 5px 0px 0px;
						-moz-border-radius: 5px 5px 0px 0px;
						-webkit-border-radius: 5px 5px 0px 0px;
						padding: 0px;
						background-color: $fire-bush;
						.btn-link,
						.btn-link:focus,
						.btn-link:visited,
						.btn-link:hover,
						.btn-link:active, {
							color: $black;
							font-family: Advent Pro, helvetica, sans-serif;
							font-size: 1em;
							text-align: left;
							text-decoration: none;
							cursor: pointer;
							white-space: normal;
							width: 100%;
						}
					}
					.card-body {
						border-bottom: 1px solid $fire-bush;
						border-left: 1px solid $fire-bush;
						border-right: 1px solid $fire-bush;
						border-radius: 0px 0px 5px 5px;
						-moz-border-radius: 0px 0px 5px 5px;
						-webkit-border-radius: 0px 0px 5px 5px;
						padding: 20px;
						margin-bottom: 20px;
						.tasks {
							font-family: Advent Pro, helvetica, sans-serif;
							font-size: 1em;
							margin-top: 10px;
							color: $fire-bush;
						}
						.certs {
							font-family: Advent Pro, helvetica, sans-serif;
							font-size: 1em;
							margin-top: 10px;
							color: $fire-bush;
						}
					}
				}
			}
		}
	}
}