@media screen and (min-width: 668px) and (max-width: 736px) {
	.abilities {
		.fas, .far {
			font-size: 9px;
		}
	}
	footer {
		.social {
			padding: 20px;
			height: 550px;
		}
		.linkedin {
			position: relative;
			top: -50px;
			left: 98px;
		}
		.follow {
			position: relative;
			top: -79px;
			left: 305px;
		}
		.facebook {
			position: relative;
			top: -114px;
			left: 531px;
		}
		.tweet-widget {
			text-align: center;
		}
		.back-to-top {
			bottom: 3px;
		}
	}
}

@media screen and (max-width: 736px) {
	.notes .quote-section {
		blockquote {
			margin: 80px 0 0 0;
		}
	}
	.related-posts {
		.articles-section {
			width: 90%;
			max-width: initial;
			padding: 0px;
		}
		.article {
			padding: 48.7% 0px 0px 0px;
			margin-bottom: 20px;
		}
	}
}