@media screen and (max-width: 1024px) {
	.related-posts {
		.articles-section {
			width: 90%;
			max-width: initial;
			padding: 0px;
		}
		.article {
			padding: 211px 0px 0px 0px;
			margin-bottom: 20px;
		}
		.article-details {
			padding: 10px;
		}
		.article h2 {
			font-size: 1em;
		}
	}
}

@media screen and (min-width: 768px) and (max-width: 1023px) {
	#menu-header-menu {
		margin-left: 42%;
	}
	.abilities {
		.fas, .far {
			font-size: 9px;
		}
	}
	.quote-section {
		h1 {
			font-size: 2em;
			padding: 20px;
			margin: 0px;
		}
		blockquote {
			font-size: 1.5em;
			padding: 0px 20px 20px 20px;
			margin: 0px;
		}
		p {
			font-size: 1em;
			padding: 0px 20px 20px 20px;
			margin: 0px;
		}
	}
	.project-background {
		.project-title {
			font-size: 2em;
			padding: 20px;
			margin: 0px;
		}
		.project-content {
			font-size: 1em;
			padding: 0px 20px 20px 20px;
			margin: 0px;
		}
	}
	.related-posts {
		.articles-section {
			width: auto;
			max-width: 200px;
			padding: 0px;
		}
		.article {
			padding: 97px 0px 0px 0px;
			margin-bottom: 20px;
		}
		.article-details {
			padding: 10px;
		}
		.article h2 {
			font-size: 1em;
		}
	}
	footer {
		h2 {
			font-size: 2em;
			padding: 0px 20px;
		}
		h3 {
			font-size: 1.3em;
			padding: 0px;
		}
		p {
			font-size: 1em;
			padding: 20px 0px;
		}
		.contact {
			padding: 20px;
		}
		.get-in-touch {
			padding: 0px 20px;
		}
		.social-title {
			padding: 20px 0px 0px 0px;
		}
		.copyright {
			font-size: 0.9em;
			padding-bottom: 85px;
			text-align: center;
		}
		.social {
			padding: 20px;
			height: 485px;
		}
		.linkedin {
			position: relative;
			top: -37px;
			left: 220px;
		}
		.follow {
			position: relative;
			top: -65px;
			left: 289px;
		}
		.facebook {
			position: relative;
			top: -99px;
			left: 404px;
		}
		.back-to-top {
			bottom: 3px;
		}
	}
}