.error404 {
	background-color: $black;
	.cat {
		margin: auto;
    	display: block;
    	object-fit: contain;
	}
	.warning {
		text-align: center;
		color: $fire-bush;
		font-family: Advent Pro, helvetica, sans-serif;
		font-size: 1em;
		padding: 20px;
	}
}

.category {
	h1 {
		font-family: Advent Pro, helvetica, sans-serif;
		font-size: 3em;
		padding: 0;
		margin: 1rem 0 1rem 0;
		color: $coral-tree;
		text-align: center;
	}
}

.black-spacer {
	background-color: $black;
}

.mtop-50 {
	margin-top: 50px;
}

.mbottom-50 {
	margin-bottom: 50px;
}

.policy {
	h1 {
		text-align: center;
		margin: 1.5em 0;
	}
	h2 {
		margin: 1.2em 0;
	}
	ul {
		li {
			margin-top: 20px;
		}
	}
}

body #ssba-bar-2.right {
	top: 43vh;
	z-index: 18;
}

.section-bar {
	position: fixed;
	top: 43vh;
	left: 0px;
	z-index: 18;
	.section-block {
		margin: 0 0 30px 0;
		a,
		a:focus,
		a:hover,
		a:visited,
		a:active {
			cursor: pointer;
			color: transparent;
			text-decoration: none;
			display: block;
			background-color: $fire-bush;
			border: 1px solid $charcoal;
			height: 5px;
			width: 30px;
			.fa-angle-down {
				display: none;
				color: $black;
    			font-size: 32px;
    			text-align: center;
    			padding: 8px 0;
			}
		}
	} 
}