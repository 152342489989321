img {
	max-width: 100%;
	height: auto;
}

.related-posts {
	.articles-section {
		width:26%;
		display:inline-block;
		margin:0px 20px;
	}
	.article {
		padding: 150px 0px 0px 0px;
		border-top-left-radius: 0px;
		-webkit-border-top-left-radius: 0px;
		-moz-border-top-left-radius: 0px;
		border-top-right-radius: 0px;
		-webkit-border-top-right-radius: 0px;
		-moz-border-top-right-radius: 0px;
	}
	.article-details {
		padding: 20px 20px;
	}
	.article h2 {
		font-size: 1em;
	}
}