#outdated {
	height: 160px;
	background-color: $black;
	text-transform: none;
	font-family: Advent Pro, helvetica, sans-serif;
	color: $fire-bush;

	span {
		font-size: 2em;
	}

	p {
		font-size: 1.5em;
	}

	#btnCloseUpdateBrowser {
		color: $fire-bush;
		font-size: 1.5em;
	}

	#btnUpdateBrowser,
	#btnUpdateBrowser:active,
	#btnUpdateBrowser:visited {
		width: 270px;
		background-color: $black;
		color: $fire-bush;
		font-size: 0.8em;
		border: 2px solid $fire-bush;
	}

	#btnUpdateBrowser:hover,
	#btnUpdateBrowser:focus {
		width: 270px;
		background-color: $fire-bush;
		color: $black;
		font-size: 0.8em;
		border: 2px solid $fire-bush;	
	}
}