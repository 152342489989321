html,
body {
  overflow-x: hidden; /* Prevent scroll on narrow devices */
  font-size: 16px;
}

body {
  padding-top: 46px;
}

.navbar {
  background-color: $white;
  .navbar-nav {
    .nav-link, .nav-link:link, .nav-link:hover, .nav-link:visited, .nav-link:active {
      font-family: Advent Pro, helvetica, sans-serif;
      font-size: 1.5em;
      color: $coral-tree;
    }
  }
}
#menu-header-menu {
  margin-left: 70%; // might need to redo this on browser resize
  li {
    padding-left: 30px;
    padding-right: 30px;
  }
}
.brand {
  height: 60px;
  width: 120px;
  background-color: $black;
  .logo {
    margin-left: 25px;
    margin-top: 5px;
    height: 50px;
    width: 63px;
  }
}

#menu-header-menu {
  li.cookie-policy,
  li.privacy-policy {
    display: none;
  }
}

@media (max-width: 767.98px) {
  .offcanvas-collapse {
    position: fixed;
    top: 60px; /* Height of navbar */
    bottom: 0;
    width: 110%;
    padding-right: 1rem;
    padding-left: 1rem;
    overflow-y: auto;
    background-color: $white;
    transition: -webkit-transform .3s ease-in-out;
    transition: transform .3s ease-in-out;
    transition: transform .3s ease-in-out, -webkit-transform .3s ease-in-out;
    -webkit-transform: translateX(-105%);
    transform: translateX(-105%);
  }
  .offcanvas-collapse.open {
    -webkit-transform: translateX(-1rem);
    transform: translateX(-1rem); /* Account for horizontal padding on navbar */
  }
  .navbar-toggler:focus,
  .navbar-toggler.open:focus,
  .navbar-toggler-line:focus {
    outline: none;
  }

  /* menu button animation */

  .navbar-toggler.open {
    span:nth-child(1) {
      -webkit-transform: rotate(45deg);
      -moz-transform: rotate(45deg);
      -o-transform: rotate(45deg);
      transform: rotate(45deg);
      top: -9px;
      left: 3px;
    }
    span:nth-child(2) {
      width: 0%;
      opacity: 0;
    }
    span:nth-child(3) {
      -webkit-transform: rotate(-45deg);
      -moz-transform: rotate(-45deg);
      -o-transform: rotate(-45deg);
      transform: rotate(-45deg);
      top: 9px;
      left: 3px;
    }
  }
  /* end menu button animation */
  
  .navbar {
    background-color: $black;
    .navbar-nav {
      .nav-link {
        padding: 0;
        color: $black;
        font-family: Advent Pro, helvetica, sans-serif;
      }
    }
  }
  .navbar {
    .navbar-nav {
      .nav-link, .nav-link:link, .nav-link:hover, .nav-link:visited, .nav-link:active {
          padding-left: 1.1em;
          padding-top: 1em;
          color: $coral-tree;
          font-family: Advent Pro, helvetica, sans-serif;
          font-size: 1.75em;
      }
    }
  }
  #menu-header-menu {
  margin-left: 0%;
    li {
      padding-left: 0px;
      padding-right: 0px;
    }
    li.cookie-policy,
    li.privacy-policy {
      display: block;
    }
  }
  .menu-button {
    padding-top: 0rem;
    padding-bottom: 0rem;
    padding-right: 0.5rem;
    padding-left: 0.5rem;
  }
  .navbar-toggler {
    width: 45px;
    height: 45px;
    position: relative;
    margin: 0px 1em 0px 0px;
    background-color: $fire-bush;
    padding: .25rem .5rem;
    -webkit-transform: rotate(0deg);
    -moz-transform: rotate(0deg);
    -o-transform: rotate(0deg);
    transform: rotate(0deg);
    -webkit-transition: .5s ease-in-out;
    -moz-transition: .5s ease-in-out;
    -o-transition: .5s ease-in-out;
    transition: .5s ease-in-out;
    cursor: pointer;
    .navbar-toggler-line {
      display: block;
      position: relative;
      height: 1px;
      width: 100%;
      background: $charcoal;
      border-radius: 9px;
      opacity: 1;
      left: 0;
      -webkit-transform: rotate(0deg);
      -moz-transform: rotate(0deg);
      -o-transform: rotate(0deg);
      transform: rotate(0deg);
      -webkit-transition: .25s ease-in-out;
      -moz-transition: .25s ease-in-out;
      -o-transition: .25s ease-in-out;
      transition: .25s ease-in-out;
    }
    span:nth-child(1) {
      top: -9px;
      -webkit-transform-origin: left center;
      -moz-transform-origin: left center;
      -o-transform-origin: left center;
      transform-origin: left center;
    }
    span:nth-child(2) {
      top: 0px;
      -webkit-transform-origin: left center;
      -moz-transform-origin: left center;
      -o-transform-origin: left center;
      transform-origin: left center;
    }
    span:nth-child(3) {
      top: 9px;
      -webkit-transform-origin: left center;
      -moz-transform-origin: left center;
      -o-transform-origin: left center;
      transform-origin: left center;
    }
  }
}

.nav-scroller {
  position: relative;
  z-index: 2;
  height: 2.75rem;
  overflow-y: hidden;
}

.nav-scroller .nav {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: nowrap;
  flex-wrap: nowrap;
  padding-bottom: 1rem;
  margin-top: -1px;
  overflow-x: auto;
  color: rgba(255, 255, 255, .75);
  text-align: center;
  white-space: nowrap;
  -webkit-overflow-scrolling: touch;
}